import logo from "../Assests/Images/logo.png";
import min_logo from "../Assests/Images/min_logo_400.png";
import sideNav_logo from "../Assests/Images/sidenav_logo.png";
import contact from "../Assests/Images/contact.png";
import pendingApprovals from "../Assests/Images/pendingApprovals.png";
import approvedUser from "../Assests/Images/verifiedUser.png";
import reportUser from "../Assests/Images/ReportedUser.png";
import tickets from "../Assests/Images/Tickets.png";
import star from "../Assests/Images/Star.png";
import Pf1 from "../Assests/Images/PF11.png";
import Pf2 from "../Assests/Images/PF12.png";
import Pf3 from "../Assests/Images/PF13.png";
import Pf4 from "../Assests/Images/PF14.png";
import C1 from "../Assests/Images/PF11.png";
import C2 from "../Assests/Images/PF12.png";
import C3 from "../Assests/Images/PF13.png";
import C4 from "../Assests/Images/PF14.png";
import C5 from "../Assests/Images/C5.png";
import C1_small from "../Assests/Images/C1_small.png";
import C2_small from "../Assests/Images/C2_small.png";
import C3_small from "../Assests/Images/C3_small.png";
import C4_small from "../Assests/Images/C4_small.png";
import C5_small from "../Assests/Images/C5_small.png";
import SAvatar from "../Assests/Images/SAvatar.png";
import footerLogo from "../Assests/Images/Footerlogo.png";
import playstore from "../Assests/Images/playstore.svg";
import appstore from "../Assests/Images/appstore.svg";
import facebook from "../Assests/Images/facebook.png";
import twitter from "../Assests/Images/twitter.svg";
import instagram from "../Assests/Images/instagram.png";
import youtube from "../Assests/Images/youtube.png";
import Works from "../Assests/Images/Works.webp";
import Banner1 from "../Assests/Images/Banner1.png";
import Banner2 from "../Assests/Images/Banner2.png";
import Banner3 from "../Assests/Images/Banner3.png";
import Banner4 from "../Assests/Images/Banner4.png";
import Banner5 from "../Assests/Images/Banner5.png";
import Banner6 from "../Assests/Images/Banner6.png";
import S1 from "../Assests/Images/S1.jpg";
import S2 from "../Assests/Images/S2.png";
import S3 from "../Assests/Images/S3.png";
import S4 from "../Assests/Images/S4.jpeg";
import S5 from "../Assests/Images/S5.jpg";

export const Image = {
  tuneConnectLogo: logo,
  tuneConnectLogo_400: min_logo,
  sideNav_logo: sideNav_logo,
};

export const DashboardImages = {
  contact: contact,
  pendingApprovals: pendingApprovals,
  approvedUser: approvedUser,
  reportUser: reportUser,
  tickets: tickets,
};

export const promoImages = {
  promoLogo: logo,
  star: star,
  Pf1: Pf1,
  Pf2: Pf2,
  Pf3: Pf3,
  Pf4: Pf4,
  C1: C1,
  C2: C2,
  C3: C3,
  C4: C4,
  C5: C5,
  C1_small: C1_small,
  C2_small: C2_small,
  C3_small: C3_small,
  C4_small: C4_small,
  C5_small: C5_small,
  SAvatar: SAvatar,
  footerLogo: footerLogo,
  playstore: playstore,
  appstore: appstore,
  facebook: facebook,
  twitter: twitter,
  instagram: instagram,
  youtube: youtube,
  Works: Works,
  Banner1: Banner1,
  Banner2: Banner2,
  Banner3: Banner3,
  Banner4: Banner4,
  Banner5: Banner5,
  Banner6: Banner6,
  S1: S1,
  S2: S2,
  S3: S3,
  S4: S4,
  S5: S5,
};
